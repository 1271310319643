<template lang="pug">
  ejs-treegrid#overviewtreegrid(ref='overviewtreegrid' :recordClick='onRowClicked' :dataSource="gridData" :pageSettings='pageOptions' :idMapping="idMapping" locale='fr' :hasChildMapping="hasChildMapping" :parentIdMapping="parentIdMapping" :treeColumnIndex="treeColumnIndex" :editSettings='editSettings' :rowDeselected="rowSelectedGrid" :rowSelected="rowSelectedGrid" :allowReordering='true' :allowPaging='allowPaging' :actionComplete='actionComplete' :clipMode="'EllipsisWithTooltip'" :gridLines='gridLines'  :allowFiltering='true' :allowSorting='true'  width='100%' height='100%' rowHeight='38' :autoFit="true"  :showColumnChooser='true'  :actionBegin='actionBegin' :loadingIndicator='loadingIndicator' :allowPdfExport='true' :allowResizing='true' :allowExcelExport='true' :enableHeaderFocus='true' :filterSettings='filterOptions' :selectionSettings='selectionSettings' :rowDataBound="rowDataBound" )
    e-columns
      e-column(v-for="(item, index) in headerData" :key="index" 
        :type='item.type' 
        :field="item.field"
        :headerText="item.headerText"
        :width="item.width"
        :maxWidth="item.maxWidth"
        :allowFiltering="item.allowFiltering"
        :allowSorting="item.allowSorting"
        :isPrimaryKey="item.isPrimaryKey"
        :showInColumnChooser="item.showInColumnChooser"
        :visible="item.visible"
        :validationRules="item.validationRules"
        :format="applyFormat(item.format)"
        :sortComparer="sortComparer"
        :template="applyTemplate(item.template)"
        :nature="item.nature"
        :defaultValue="item.defaultValue"
        :textAlign="item.textAlign ? item.textAlign : ''"
        :headerTextAlign="item.textAlign ? item.textAlign : ''"
        :valueAccessor="applyFormatter(item.valueAccessor)"
        :displayAsCheckBox="displayAsCheckBox"
        :allowEditing="item.allowEditing"
        :editType="item.editType"
        :columns="item.columns"
        :edit='applyEdit(item.editType, item.edit)'
        :customAttributes="item.customCss ? {class: item.customCss} :''"
        :filter="item.filter ? item.filter : item.filterTemplate ? applyFilterTemplate(item.filterTemplate) : { type: 'Excel' }"
        
      )
  </template>
<script>
import { mapGetters } from "vuex";

import FilterTags from "@/components/global/tags/FilterTags";
import CellRendererBoolean from "@/components/global/ag-grid/CellRendererBoolean.vue";
import CellRendererCollaborator from "@/components/global/ag-grid/CellRendererCollaborator.vue";
import CellRendererActive from "@/components/global/ag-grid/CellRendererActive.vue";
import CellRendererDefault from "@/components/global/ag-grid/CellRendererDefault.vue";
import CellRendererColor from "@/components/global/ag-grid/CellRendererColor.vue";
import CellRendererCivility from "@/components/global/ag-grid/CellRendererCivility.vue";
import CellRendererStatus from "@/components/global/ag-grid/CellRendererStatus.vue";
import CellRendererCurrency from "@/components/global/ag-grid/CellRendererCurrency.vue";
import CellRendererTags from "@/components/global/ag-grid/CellRendererTags.vue";
import CellRendererProductType from "@/components/global/ag-grid/CellRendererProductType.vue";
import CellRendererPaymentType from "@/components/global/ag-grid/CellRendererPaymentType.vue";
import CellRendererStripHtml from "@/components/global/ag-grid/CellRendererStripHtml.vue";
import CellRendererType from "@/components/global/ag-grid/CellRendererType.vue";
import CellRendererUnit from "@/components/global/ag-grid/CellRendererUnit.vue";
import SumFooter from "@/components/global/ag-grid/SumFooter.vue";
import SumCurrencyFooter from "@/components/global/ag-grid/SumCurrencyFooter.vue";

import { unitPeriodicityTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

import {
  AutoComplete,
  DropDownTree,
  CheckBoxSelection,
  DropDownList,
} from "@syncfusion/ej2-dropdowns";
import {
  Page,
  Edit,
  ColumnChooser,
  Filter,
  Sort,
  Reorder,
  ExcelExport,
  Resize,
  DataStateChangeEventArgs,
} from "@syncfusion/ej2-vue-treegrid";
import { Query } from "@syncfusion/ej2-data";

import {
  formatCurrency,
  formatNumber,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { ColorPicker } from "@syncfusion/ej2-vue-inputs";

import { MultiSelect } from "@syncfusion/ej2-dropdowns";
MultiSelect.Inject(CheckBoxSelection);

let action;

export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    treeColumnIndex: {
      type: Number,
      default: 0,
    },
    idMapping: {
      type: String,
      default: "id",
    },
    hasChildMapping: {
      type: String,
      default: "parentId",
    },
    parentIdMapping: {
      type: String,
      default: "parentId",
    },
    editSettings: {
      type: Object,
      default: null,
    },
    selectionSettings: {
      type: Object,
      default: null,
    },
    allowGrouping: {
      type: Boolean,
      default: true,
    },
    gridData: {
      type: Array,
      default: () => [],
    },
    headerData: {
      type: Array,
      default: () => [],
    },
    tagguerButton: {
      type: Boolean,
      default: false,
    },
    allowPaging: {
      type: Boolean,
      default: true,
    },
    companyTypeId: {
      type: Number,
      default: null,
    },
    displayAsCheckBox: {
      type: Boolean,
      default: false,
    },
    gridLines: {
      type: String,
      default: "Both",
    },
    archiveMode: {
      type: Boolean,
      default: false,
    },
    pageOptions: {
      type: Object,
      default: () => {
        return {
          currentPage: 1,
          pageSize: 20,
          pageCount: 8,
          pageSizes: true,
        };
      },
    },
  },
  components: {
    CellRendererBoolean,
    CellRendererCollaborator,
    CellRendererActive,
    CellRendererDefault,
    CellRendererColor,
    CellRendererCivility,
    CellRendererStatus,
    CellRendererTags,
    CellRendererProductType,
    CellRendererPaymentType,
    CellRendererStripHtml,
    FilterTags,
    SumFooter,
    SumCurrencyFooter,
    CellRendererType,
  },
  data() {
    return {
      // valueUnitsFilter: [],
      // valueAnalyticsSettingsFilter: [],
      formatDateOptions: { type: "date", format: "dd/MM/yyyy" },
      formatDateTimeOptions: { type: "date", format: "dd/MM/yyyy" },
      loadingIndicator: { indicatorType: "Shimmer" },

      tagsTemplate() {
        return {
          template: CellRendererTags,
        };
      },
      productTypeTemplate() {
        return {
          template: CellRendererProductType,
        };
      },
      paymentTypeTemplate() {
        return {
          template: CellRendererPaymentType,
        };
      },
      booleanTemplate() {
        return {
          template: CellRendererBoolean,
        };
      },
      linkDocumentTemplate() {
        return {
          template: null,
        };
      },

      collaboratorTemplate() {
        return {
          template: CellRendererCollaborator,
        };
      },
      activeTemplate() {
        return {
          template: CellRendererActive,
        };
      },
      defaultTemplate() {
        return {
          template: CellRendererDefault,
        };
      },
      colorTemplate() {
        return {
          template: CellRendererColor,
        };
      },
      civilityTemplate() {
        return {
          template: CellRendererCivility,
        };
      },
      statusTemplate() {
        return {
          template: CellRendererStatus,
        };
      },
      currencyTemplate() {
        return {
          template: CellRendererCurrency,
        };
      },
      stripHtmlTemplate() {
        return {
          template: CellRendererStripHtml,
        };
      },
      footerSumTemplate() {
        return {
          template: SumFooter,
        };
      },
      typeTemplate() {
        return {
          template: CellRendererType,
        };
      },
      unitTemplate() {
        return {
          template: CellRendererUnit,
        };
      },
      footerSumCurrencyTemplate() {
        return {
          template: SumCurrencyFooter,
        };
      },
      filterOptions: { type: "Menu", hierarchyMode: "Child" },
    };
  },
  provide: {
    treegrid: [
      Page,
      Edit,
      ColumnChooser,
      Filter,
      Sort,
      Reorder,
      ExcelExport,
      Resize,
    ],
    //   treegrid: [Filter, Selection, Sort, VirtualScroll, Reorder, Group, Resize, Page, ExcelExport, ColumnChooser, Aggregate, Toolbar, Edit]
  },
  computed: {
    ...mapGetters(["workspaceSelected", "tagsList", "unitsList"]),
  },
  methods: {
    formatCurrency,
    unitPeriodicityTranslate,
    sortComparer: function (reference, comparer) {
      let sortAsc = action === "Ascending" ? true : false;
      if (sortAsc && reference === null) {
        return 1;
      } else if (sortAsc && comparer === null) {
        return -1;
      } else if (!sortAsc && reference === null) {
        return -1;
      } else if (!sortAsc && comparer === null) {
        return 1;
      } else {
        return reference - comparer;
      }
    },
    rowDataBound(args) {
      this.$emit("rowDataBound", args);
    },
    actionComplete(args) {
      let _this = this;
      if (
        this.name == "analyticsSetting" &&
        (args.requestType === "save" || args.requestType === "cancel")
      ) {
        this.$refs.overviewtreegrid.hideColumns(["Code parent"]);
      }
      if (args.requestType === "beginEdit" || args.requestType === "add") {
        let dialog = args.dialog;
        dialog.width = 400;
        dialog.header =
          args.requestType === "beginEdit"
            ? this.editSettings.titleEdit +
              " : " +
              args.rowData[this.editSettings.titleField]
            : this.editSettings.titleAdd;

        if (this.archiveMode) {
          let footerArchiveButton = {
            buttonModel: {
              content: "",
              cssClass: "e-outline e-danger m-0 float-left",
              iconCss: "e-btn-sb-icons e-icon-archive",
            },
            click: function () {
              _this.$emit("archiveButtonClicked", args);
            },
          };
          args.dialog.buttons.unshift(footerArchiveButton);
        }
        if (
          this.editSettings &&
          this.editSettings.allowDeleting &&
          args.rowData.id
        ) {
          let footerDeleteButton = {
            buttonModel: {
              content: "",
              cssClass: "e-outline e-danger m-0 float-left",
              iconCss: "e-btn-sb-icons e-icon-trash",
            },
            click: function () {
              _this.$emit("deleteButtonClicked", args);
            },
          };
          args.dialog.buttons.unshift(footerDeleteButton);
        }
        args.dialog.refresh();
      }
    },
    actionBegin: function (args) {
      this.$emit("actionBegin", args);
      if (args.requestType == "sorting") {
        action = args.direction;
      }

      if (this.name == "analyticsSetting") {
        if (args.requestType === "add" || args.requestType === "beginEdit") {
          this.$refs.overviewtreegrid.showColumns(["Code parent"]);
        }
      } else {
        if (args.requestType === "beginEdit" || args.requestType === "add") {
          for (
            var i = 0;
            i < this.$refs.overviewtreegrid.getColumns().length;
            i++
          ) {
            if (
              this.$refs.overviewtreegrid.getColumns()[i].field == "parentId"
            ) {
              this.$refs.overviewtreegrid.getColumns()[i].visible = true;
            } else if (
              this.$refs.overviewtreegrid.getColumns()[i].field == "id"
            ) {
              this.$refs.overviewtreegrid.getColumns()[i].visible = false;
            }
          }
        }
        if (args.requestType === "save") {
          for (
            var i = 0;
            i < this.$refs.overviewtreegrid.getColumns().length;
            i++
          ) {
            if (
              this.$refs.overviewtreegrid.getColumns()[i].field == "parentId"
            ) {
              this.$refs.overviewtreegrid.getColumns()[i].visible = false;
            }
          }
        }
      }

      if (args.requestType === "save" && args.action === "add") {
        // Lors de l'ajout on annule l'action de base pour pouvoir ajouter et récupérer l'ID, sinon la nouvelle ligne se met avec un id à 0
        args.cancel = true;
        this.$refs.overviewtreegrid.closeEdit();
      }
    },
    unitPeriodicityFormatter(field, data, column) {
      return this.unitPeriodicityTranslate(data.unit);
    },
    collaboratorFormatter(field, data, column) {
      if (data.collaboratorId) {
        return (
          this.collaboratorsList.find((el) => el.id == data.collaboratorId)
            .firstName +
          " " +
          this.collaboratorsList.find((el) => el.id == data.collaboratorId)
            .lastName
        );
      } else {
        return "";
      }
    },
    userFormatter(field, data, column) {
      if (data.userId) {
        return data.userFirstName + " " + data.userLastName;
      } else {
        return "";
      }
    },
    dropdownFormatter(field, data, column) {
      return data[field] &&
        column.edit.params &&
        column.edit.params.dataSource.find((el) => el.id == data[field])
        ? column.edit.params.dataSource.find((el) => el.id == data[field])[
            column.edit.params.fields.text
          ]
        : data[field] &&
          column.edit.dataSource.find((el) => el.id == data[field])
        ? column.edit.dataSource.find((el) => el.id == data[field])[
            column.edit.fields.text
          ]
        : "--";
    },
    companyCustomerAddressFormatter(field, data, column) {
      const address = data.companyCustomerAddress
        ? data.companyCustomerAddress
        : "";
      const zipCode = data.companyCustomerZipCode
        ? data.companyCustomerZipCode
        : "";
      const city = data.companyCustomerCity ? data.companyCustomerCity : "";
      return address + " " + zipCode + " " + city;
    },
    situationRemainingPercent: function (field, data, column) {
      return formatNumber(100 - data["situationProgress"]) + " %";
    },
    situationRemainingQuantity: function (field, data, column) {
      return formatNumber(
        ((100 - data["situationProgress"]) / 100) * data["quantity"]
      );
    },
    situationRemainingAmount: function (field, data, column) {
      return formatCurrency(
        (data["unitPriceHt"] *
          data["quantity"] *
          (100 - data["situationProgress"])) /
          100
      );
    },
    situationProgressAmount: function (field, data, column) {
      return formatCurrency(
        (data["unitPriceHt"] * data["quantity"] * data["situationProgress"]) /
          100
      );
    },
    situationProgressQuantity: function (field, data, column) {
      return formatNumber((data["situationProgress"] / 100) * data["quantity"]);
    },
    totalPriceHT: function (field, data, column) {
      return formatCurrency(data["unitPriceHt"] * data["quantity"]);
    },
    sumAffairCostingCurrencyFormatter: function (field, data, column) {
      return formatCurrency(data["valuatedQuantity"] * data["amountUnit"]);
    },
    quantityFormatter: function (field, data, column) {
      return formatNumber(data[field]);
    },
    currencyFormatter: function (field, data, column) {
      return formatCurrency(data[field]);
    },
    percentFormatter: function (field, data, column) {
      return formatNumber(data[field]) + " %";
    },
    marginCostingFormatter: function (field, data, column) {
      if (data["totalCA"] > 0) {
        return (
          formatNumber(
            ((data["totalCA"] - data["totalOfExpenses"]) / data["totalCA"]) *
              100
          ) + " %"
        );
      } else {
        return "0 %";
      }
    },
    fullNameCollaborator(field, data, column) {
      return data["collaborator"].displayLabel;
    },
    booleanStandardFormat(field, data, column) {
      return data[field] ? "Oui" : "Non";
    },
    onRowClicked(args) {
      var gridObj = this.$refs.overviewgrid;
      if (args.cellIndex !== 0) {
        if (gridObj.getSelectedRows().length > 0) {
          gridObj.clearSelection();
        }
        this.$emit("rowClicked", args);
      }
    },
    applyTemplate(template) {
      if (template && template == "stripHtmlTemplate") {
        return this.stripHtmlTemplate;
      } else if (template && template == "productTypeTemplate") {
        return this.productTypeTemplate;
      } else if (template && template == "paymentTypeTemplate") {
        return this.paymentTypeTemplate;
      } else if (template && template == "tagsTemplate") {
        return this.tagsTemplate;
      } else if (template && template == "defaultTemplate") {
        return this.defaultTemplate;
      } else if (template && template == "activeTemplate") {
        return this.activeTemplate;
      } else if (template && template == "booleanTemplate") {
        return this.booleanTemplate;
      } else if (template && template == "linkDocumentTemplate") {
        return this.linkDocumentTemplate;
      } else if (template && template == "collaboratorTemplate") {
        return this.collaboratorTemplate;
      } else if (template && template == "colorTemplate") {
        return this.colorTemplate;
      } else if (template && template == "civilityTemplate") {
        return this.civilityTemplate;
      } else if (template && template == "statusTemplate") {
        return this.statusTemplate;
      } else if (template && template == "currencyTemplate") {
        return this.currencyTemplate;
      } else if (template && template == "typeTemplate") {
        return this.typeTemplate;
      } else if (template && template == "unitTemplate") {
        return this.unitTemplate;
      } else {
        return null;
      }
    },
    applyFormat(format) {
      if (format && format == "formatDateTimeOptions") {
        return { type: "date", format: "dd/MM/yyyy HH:mm" };
      } else if (format && format == "formatDateOptions") {
        return { type: "date", format: "dd/MM/yyyy" };
      } else if (format && format == "formatCurrency") {
        return { type: "currency", currency: "EUR" };
      } else if (format && format == "formatNumber") {
        return { type: "number", maximumFractionDigits: 0 };
      } else {
        return null;
      }
    },
    dataExcelTemplate(itemToFiltered) {
      switch (itemToFiltered) {
        case "unitId":
          return this.unitsList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.unitId;
              })
              .includes(item.id)
          );
          break;
        case "analyticsSettingId":
          return this.$store.getters["analyticsSettingsList"].filter((item) =>
            this.gridData
              .map((data) => {
                return data.analyticsSettingId;
              })
              .includes(item.id)
          );
          break;
        case "parentAccountNumber":
          let parentAccountNumberList = this.gridData.filter(
            (elem) => elem.parentAccountNumber
          );
          let parentList = [];
          let j = 0;

          for (let i = 0; i < parentAccountNumberList.length; i++) {
            const element = parentAccountNumberList[i];
            if (
              parentList.length === 0 ||
              (parentList.length > 0 &&
                parentList[j - 1]?.id !==
                  this.gridData[
                    this.gridData.findIndex(
                      (elem) => elem.id === element.parentAccountNumber
                    )
                  ]?.id)
            ) {
              parentList.push(
                this.gridData[
                  this.gridData.findIndex(
                    (elem) => elem.id === element.parentAccountNumber
                  )
                ]
              );
              j++;
            }
          }

          return parentList.map((elem) => {
            return { displayLabel: elem.accountNumber, id: elem.id };
          });
          break;
        default:
          return null;
      }
    },
    applyFilterTemplate(filterTemplate) {
      if (filterTemplate && filterTemplate == "unitFilterTemplate") {
        return this.filterTemplateCustomise("unitId", "label");
      } else if (
        filterTemplate &&
        filterTemplate == "analyticsSettingFilterTemplate"
      ) {
        return this.filterTemplateCustomise("analyticsSettingId");
      } else if (filterTemplate && filterTemplate == "parentAccountNumber") {
        return this.filterTemplateCustomise("parentAccountNumber");
      }
    },
    filterTemplateCustomise(columnLabel, fieldsText) {
      let _this = this;
      let dataFiltered = [];
      return {
        ui: {
          create: function (args) {
            args.getOptrInstance.dropOptr.element.parentElement.parentElement.style.display =
              "none";
            let flValInput = document.createElement("input", {
              className: "flm-input",
            });
            let isFiltered = event.target.classList.contains("e-filtered");
            _this.multiSelect = new MultiSelect({
              placeholder: "Selectionner...",
              dataSource: _this.dataExcelTemplate(columnLabel),
              fields: {
                text: fieldsText ? fieldsText : "displayLabel",
                value: "id",
              },
              value: isFiltered ? dataFiltered : null,
              mode: "CheckBox",
              showSelectAll: "showSelectAll",
              selectAllText: "Tous",
              unSelectAllText: "Aucun",
              change: function (e) {
                if (e.value.length > 0) {
                  dataFiltered = e.value;
                  _this.$refs.overviewtreegrid.clearFiltering([
                    args.column.field,
                  ]);
                  _this.$refs.overviewtreegrid.filterByColumn(
                    args.column.field,
                    "equal",
                    e.value
                  );
                } else {
                  _this.$refs.overviewtreegrid.clearFiltering([
                    args.column.field,
                  ]);
                }
              },
            });
            args.target.appendChild(flValInput);
            _this.multiSelect.appendTo(flValInput);
          },
          write: function (args) {},
          read: function (args) {},
        },
      };
    },
    applyEdit(editType, options) {
      let _this = this;
      if (
        !editType &&
        options &&
        options.source &&
        options.type &&
        options.type == "dropdowntree"
      ) {
        let element;
        let dropdownobj;

        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            dropdownobj.destroy();
          },
          read: function () {
            return dropdownobj.value;
          },
          write: function (args) {
            if (args.rowData[args.column.field]) {
              args.rowData[args.column.field] = [
                args.rowData[args.column.field].toString(),
              ];
            } else {
              args.rowData[args.column.field] = [];
            }
            dropdownobj = new DropDownTree({
              dataSource: options.source,
              value: args.rowData[args.column.field],
              query: new Query(),
              fields: options.fields,
              floatLabelType: "Always",
              placeholder: args.column.headerText,
            });
            dropdownobj.appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.source &&
        options.type &&
        options.type == "autocomplete"
      ) {
        let inpuEle;
        let autoCompleteIns;
        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            inpuEle = document.createElement("input");
            return inpuEle;
          },
          destroy: function () {
            autoCompleteIns.destroy();
          },
          read: function (args) {
            return autoCompleteIns.value;
          },
          write: function (args) {
            // if(args.rowData[args.column.field] ){
            //     args.rowData[args.column.field] = [args.rowData[args.column.field].toString()]
            // }else{
            //     args.rowData[args.column.field] = []
            // }
            autoCompleteIns = new AutoComplete({
              allowCustom: true,
              dataSource: options.source,
              value: args.rowData[args.column.field],
              query: new Query(),
              fields: options.fields,
              floatLabelType: "Always",
              placeholder: args.column.headerText,
              select: function (selectedItem) {
                // _this.$emit("actionBegin", {...args, selectedItem:selectedItem});
                // _this.$emit("autocompleteBegin", document)
                // let formEle = document.getElementById("overviewtreegrid").querySelector("form").ej2_instances[0];
                // var quantity = formEle.getInputElement("quantity");
                // quantity.value = 250;
                // // var quantity = formEle.getInputElement("quantity");
                // // quantity.value = 20;
                // var quantity = _this.$el.ej2_instances[0].getInputElement("quantity");
                // quantity.value = 20;
                // args.rowData.quantity = 20
                // args.rowData = {
                //     ...selectedItem.itemData,
                //     analyticsSettingId: selectedItem.itemData.analyticsSettingId ? [selectedItem.itemData.analyticsSettingId] : [],
                //     parentId: selectedItem.itemData.parentId ? [selectedItem.itemData.parentId] : []
                // }
              },
            });
            autoCompleteIns.appendTo(inpuEle);
          },
        };
      } else if (editType == "dropdownedit" && options && options.source) {
        let edit = {
          params: {
            allowFiltering: true,
            dataSource: options.source,
            fields: options.fields,
            query: new Query(),
            actionComplete: () => false,
          },
        };
        return edit;
      } else if (editType == "numericedit") {
        return options;
      } else if (
        !editType &&
        options &&
        options.source &&
        options.type &&
        options.type == "dropdown"
      ) {
        let element;
        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            _this[options.name].destroy();
          },
          read: function () {
            return _this[options.name].value;
          },
          write: function (args) {
            _this[options.name] = new DropDownList({
              dataSource: options.source,
              allowFiltering: true,
              filterType: "Contains",
              sortOrder: "Ascending",
              floatLabelType:
                _this.editSettings && _this.editSettings.mode == "Dialog"
                  ? "Always"
                  : "Never",
              value: args.rowData[args.column.field],
              query: new Query(),
              fields: options.fields,
              placeholder: args.column.headerText,
            });
            _this[options.name].appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.type &&
        options.type == "colorpicker"
      ) {
        let colorPickerObj;
        let elem;
        return {
          create: function () {
            elem = document.createElement("input");
            return elem;
          },
          destroy: function () {
            colorPickerObj.destroy();
          },
          read: function () {
            return colorPickerObj.value;
          },
          write: function (args) {
            colorPickerObj = new ColorPicker({
              value: args.rowData[args.column.field],
            });
            colorPickerObj.appendTo(elem);
          },
        };
      } else {
        return null;
      }
    },
    applyFormatter(formatter) {
      if (formatter && formatter == "quantity") {
        return this.quantityFormatter;
      } else if (formatter && formatter == "currency") {
        return this.currencyFormatter;
      } else if (formatter && formatter == "marginCosting") {
        return this.marginCostingFormatter;
      } else if (formatter && formatter == "percent") {
        return this.percentFormatter;
      } else if (formatter && formatter == "situationRemainingPercent") {
        return this.situationRemainingPercent;
      } else if (formatter && formatter == "situationRemainingQuantity") {
        return this.situationRemainingQuantity;
      } else if (formatter && formatter == "situationProgressQuantity") {
        return this.situationProgressQuantity;
      } else if (formatter && formatter == "totalPriceHT") {
        return this.totalPriceHT;
      } else if (formatter && formatter == "situationProgressAmount") {
        return this.situationProgressAmount;
      } else if (formatter && formatter == "situationRemainingAmount") {
        return this.situationRemainingAmount;
      } else if (
        formatter &&
        formatter == "sumAffairCostingCurrencyFormatter"
      ) {
        return this.sumAffairCostingCurrencyFormatter;
      } else if (formatter && formatter == "user") {
        return this.userFormatter;
      } else if (formatter && formatter == "companyCustomerAddress") {
        return this.companyCustomerAddressFormatter;
      } else if (formatter && formatter == "dropdown") {
        return this.dropdownFormatter;
      } else if (formatter && formatter == "fullNameCollaborator") {
        return this.fullNameCollaborator;
      } else if (formatter && formatter == "boolean") {
        return this.booleanStandardFormat;
      } else if (formatter && formatter == "unitPeriodicity") {
        return this.unitPeriodicityFormatter;
      } else {
        return null;
      }
    },
    onFilterTextBoxChanged(input) {
      this.$refs.overviewtreegrid.search(input);
    },
    rowSelectedGrid() {
      this.$emit(
        "setSelectedRows",
        this.$refs.overviewtreegrid.getSelectedRecords()
      );
    },
    setTagsSelected(val) {
      this.$emit("setTagsSelected", val);
    },
    exportData(type) {
      let selectedRecords = this.$refs.overviewtreegrid.getSelectedRecords();
      let exportProperties = {
        dataSource:
          selectedRecords.length > 0 ? selectedRecords : this.gridData,
        enableFilter: true,
      };
      if (type == "csv") {
        this.$refs.overviewtreegrid.csvExport(exportProperties);
      } else if (type == "xls") {
        this.$refs.overviewtreegrid.excelExport(exportProperties);
      } else if (type == "print") {
        this.$refs.overviewtreegrid.print(exportProperties);
      }
    },
  },
};
</script>
