<template lang="pug">
div {{item}}
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      data: null,
      item: null,
    };
  },
  computed: {
    ...mapGetters(["unitsList"]),
  },
  created() {
    this.item = this.unitsList.find(
      (unit) => unit.id === this.data.unitId
    ).label;
  },
};
</script>
<style lang=""></style>
